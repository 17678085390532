import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { toast } from "react-toastify";
import { ScrollFixer } from "utils/ScrollFixer";
import { Helmet } from "react-helmet";

import Theme from "styles/theme";
import "./config/ReactotronConfig";
import { store, persistor } from "./store";

import { Routes } from "routes";
import TagManager from "react-gtm-module";
import GlobalStyle from "./styles/global";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

toast.configure();
TagManager.initialize({ gtmId: "GTM-KVH9ZJW" });
const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename="/">
          <Helmet>
            <script
              src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/4c5bd399-1fa3-4b80-a7ed-f38b8cef8214-loader.js"
              type="text/javascript"
            />
          </Helmet>
          <ScrollFixer />
          <Theme>
            <GlobalStyle />
            <Routes />
          </Theme>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
