import React from "react";

import * as S from "./styles";

export const Investment: React.FC = () => {
  return (
    <S.Container>
      <S.Title>Investimento</S.Title>
      <S.Text>
        O investimento para implantação do projeto irá depender do nível de
        adequação do ponto comercial escolhido. Temos três projetos de clínicas
        desenvolvidos nos tamanhos <span>pequeno, médio e grande.</span>
      </S.Text>
      <S.GroupPlans>
        <S.Card>
          <S.ImgSmall alt="clinica pequena" />
          <h2>
            Clínica
            <br />
            Pequena
          </h2>
          <p>3 Cadeiras de dentista</p>
          <p className="price">R$500.000</p>
        </S.Card>
        <S.Card>
          <S.ImgMedium alt="clinica Média" />
          <h2>
            Clínica
            <br />
            Média
          </h2>
          <p>4 Cadeiras de dentista</p>
          <p className="price">R$650.000</p>
        </S.Card>
        <S.Card>
          <S.ImgBig alt="clinica Grande" />
          <h2>
            Clínica
            <br />
            Grande
          </h2>
          <p>7 Cadeiras de dentista</p>
          <p className="price">R$850.000</p>
        </S.Card>
      </S.GroupPlans>
    </S.Container>
  );
};
